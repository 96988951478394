@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';

.userIcons {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.menu {
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;

  a {
    align-items: center;
    color: inherit;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    text-decoration: none;
    width: 28px;
    height: 28px;

    @media screen and (min-width: vars.$medium) {
      width: initial;
      height: initial;
    }

    p {
      display: none;
      font-stretch: condensed;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      padding: 0 8px;

      @media screen and (min-width: vars.$medium) {
        display: block;
        margin-top: 6px;
      }
    }

    img {
      border-radius: 50%;
      max-height: 24px;
      max-width: 24px;

      @media screen and (min-width: vars.$medium) {
        max-width: 44px;
        max-height: 44px;
      }

      @media screen and (min-width: vars.$extra-extra-large) {
        max-height: 43px;
        max-width: 43px;
      }
    }

    svg {
      width: 24px;
      height: 25px;

      @media screen and (min-width: vars.$medium) {
        width: 16px;
        height: 16px;
      }
    }

    & .cartIcon {
      width: 26px;
      height: 28px;

      @media screen and (min-width: vars.$medium) {
        width: 20px;
        height: 20px;
      }
    }

    &.loggedIn svg {
      height: 20px;
      width: 20px;

      @media screen and (min-width: vars.$extra-extra-large) {
        height: 42px;
        width: 42px;
      }
    }
  }
}

.isOpen {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: calc(100% + 14px);
    left: 50%;
    transform: translateX(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
    transition: opacity 0.5s ease, visibility 0.5s ease, transform 0.5s ease;
    opacity: 1;
    visibility: visible;
  }
}

.hiddenArrow {
  &::after {
    opacity: 0;
    visibility: hidden;
  }
}

.hasItems {
  position: relative;
}

.counterBadge {
  align-items: center;
  background-color: colors.$primary-color;
  border-radius: 50%;
  box-sizing: content-box;
  color: colors.$white;
  display: flex;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  font-stretch: condensed;
  justify-content: center;
  max-height: 18px;
  max-width: 18px;
  min-height: 18px;
  min-width: 18px;
  position: absolute;
  left: 52%;
  top: -5px;
}

.userIconsAlternative {
  .counterBadge {
    background-color: #ff4b50;
    border: 2px solid colors.$primary-color;
    top: -10px;
  }
  .wishlistIcon {
    & svg,
    &.hasItems svg {
      color: white;
    }
  }
}

.badgeCounterCountainer {
  position: relative;
}

.wishlistIcon {
  &.hasItems {
    svg {
      color: colors.$red;
    }
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}

a.avatarLoading svg {
  width: 43px;
  height: 43px;
  animation-name: blink;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
}
